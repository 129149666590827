import { Suspense, lazy } from 'react';
import './App.css';
// import Home from './component/Home';

const Home = lazy(() => import('./component/Home'));

function App() {
  return (
    <Suspense>
      <Home />
    </Suspense>
  );
}

export default App;
